import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`La gírgola de pi té forma d’orella o copinya amb la part superior del capell oberta com un ventall i el peu que sembla una continuació del mateix, que pot ser totalment excèntric o lateral. El capell mesura de 5 a 14 cm de diàmetre, és gelatinós en temps humit, llis, lluent i amb taques blanquinoses i de color de gris a marró fosc. El peu és una mica més clar que el capell contrastant amb les làmines que són blanquinoses, atapeïdes i molt decurrents. Les espores són blanques en massa, el·líptiques, llises, de 7-10 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      